.aboutusPage {
    width: 100%;
    height: auto;
    /* background-color: #222323cc; */
    background: linear-gradient(180deg, #000328, #00458e);
    margin-top: 30px;
}

.ourMission {
    width: 100%;
    height: 700px;
    position: relative;
    padding: 100px;
}

.ourMission .imageDiv {
    width: 65%;
    height: 400px;
    background-repeat: no-repeat;
    background-position: center !important;
    background-size: cover !important;
}

.ourMission .textDiv {
    width: 50%;
    height: auto;
    /* background-color: #000; */
    background: linear-gradient(239deg, #000328, #00458e);

    position: absolute;
    top: 25%;
    right: 5%;
    padding: 50px;
}

.ourMission .innerText {
    width: 80%;
    margin: 0 auto;
}

.ourMission .innerText h1 {
    color: #fff;
    font-size: 3.2rem;
    margin-bottom: 20px;
}

.ourMission .innerText p {
    color: #fff;
    font-weight: 300 !important;
}