.animation {
    padding: 50px;
    /* background: url('./../imgs/animation.webp') no-repeat; */
    background-position: center;
    background-size: cover;
    position: relative;
    margin-top: 50px;
}

.overlay {
    position: relative;
    z-index: 1;
}

.animation::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000328, #00458e);
    z-index: 0;
}

.animation p {
    color: #fff;
    line-height: 1.5;
    font-size: 1.2rem;
    font-weight: 200;
}

.video-react .video-react-big-play-button {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}