.portfolioSection {
    width: 100%;
    padding: 50px 20px;
    background: linear-gradient(180deg, #000328, #00458e);
    margin-top: 30px;
}



.portfolioSection .slick-slide {
    margin: 5px;
}

.portfolioSlideItemImage {
    width: 100%;
    min-height: 300px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    margin: 5px;
    transition: all 0.2s linear;
}

.portfolio {
    transition: all 0.2s linear;

}

.portfolioSlideItemImage:hover,
.portfolio:hover {
    transform: scale(1.03);
}


.portfolioSection .slick-next {
    right: 20px !important;
}

.portfolioSection .slick-prev {
    left: 25px !important;
    z-index: 1;
}

.portfolioSection .slick-prev:before,
.portfolioSection .slick-next:before {
    color: var(--purple) !important;
    font-size: 35px !important;
}

.animationParent {
    width: 100%;
    height: 600px;
    position: relative;
}

.arrowImage {
    position: absolute;
    right: -40px;
    top: 60px;
    transform: rotate(340deg);
    width: 120px;
    height: 100px;
}


.animationParent .video {
    position: absolute;
    top: 50%;
    left: 70%;
    transform: translate(-50%, -50%) rotate(5deg) scale(0.8);
    -webkit-transform: translate(-50%, -50%) rotate(5deg) scale(0.8);
    width: 80%;
    height: 430px;
    z-index: 0;
    transition: all 1s ease-in-out;
    -webkit-box-shadow: 17px 21px 52px -11px rgba(0, 0, 0, 0.9);
    -moz-box-shadow: 17px 21px 52px -11px rgba(0, 0, 0, 0.9);
    box-shadow: 17px 21px 52px -11px rgba(0, 0, 0, 0.9);

}

.animationParent .video .video-react {
    width: 100%;
    height: 100%;
}

.video-react {
    border-radius: 10px !important;
}

.animationParent .video.false {
    opacity: 0.5;
}

.animationParent .video.active {
    top: 50%;
    left: 50%;
    border-radius: 10px;
    width: 80%;
    height: 430px;
    overflow: hidden;
    z-index: 1;
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    animation: activeDiv 2s 1;
    box-shadow: none;
}



@keyframes activeDiv {
    0% {
        transform: translate(-50%, -50%) rotate(0deg) scale(0.8);
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(0.8);

    }

    50% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }

    100% {
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
}

.portfolioDetail {
    width: 100%;
    min-height: 400px;
    margin-top: 100px;
    padding: 100px 0;
}

.portfolioDetail .headingTextArea {
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.portfolioDetail .headingTextArea a {
    color: var(--orng);
    text-decoration: none;
    font-weight: 400;
}

.portfolioDetail .headingTextArea h2 {
    font-size: 3rem;
    line-height: 1 !important;
    color: #fff;
    font-weight: 600;
    margin: 20px 0;
}

.portfolioDetail .headingTextArea h6 {
    color: var(--yle);
    font-weight: 500;
}

.portfolioDetail .headingTextArea p {
    color: #fff;
    font-size: 0.9rem;
}

.portfolioDetail .hero-video {
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    min-height: 400px;
}

.portfolioDetail .hero-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.portfolioDetail .portfolioDetailChallenges {
    background: #022c59;
    padding: 50px;
    width: 100%;
    margin: 100px 0 0 0;
}

.portfolioDetail .portfolioDetailChallengesText {
    padding: 30px;
}

.portfolioDetail .portfolioDetailChallengesText h3 {
    color: #fff;
    margin-bottom: 15px;
    font-family: "Dancing Script", cursive !important;
    font-size: 3rem;
}

.portfolioDetail .portfolioDetailChallengesText p,
.portfolioDetail .portfolioDetailChallengesText ul li,
.portfolioDetail .portfolioDetailChallengesText ol li {
    color: #fff;
    font-size: 0.9rem;
    /* text-align: justify; */
}


.portfolioDetailBehindTheSeen {
    padding: 50px;
    width: 100%;
    /* margin: 100px 0; */
}

.titleSection {
    color: #fff;
    margin-bottom: 15px;
    font-family: "Dancing Script", cursive !important;
    font-size: 3rem;
}