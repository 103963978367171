.heroSection {
    width: 100%;
    background: url('./../imgs/shoe.svg') no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.heroSection video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}

.videoOverlay {
    width: 90%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 100px;
}

.heroSectionText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 10px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    background: rgba(16, 16, 16, 0.62);
    border-radius: 10px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.4px);
    -webkit-backdrop-filter: blur(6.4px);
    border: 1px solid rgba(16, 16, 16, 0.55);
}

.heroSectionText h2 {
    color: #fff;
    font-size: 2.3rem;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    width: 100%;
}

.heroSectionText p {
    color: #fff;
    margin: 20px 0;
    font-size: 1.4rem;
    font-weight: 300;
    text-align: center;
}

.react-player {
    position: relative;
    border-radius: 5px;
    z-index: 1;
}

.react-player::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 101%;
    height: 102%;
    background: linear-gradient(139deg, #081185, #2c88eb);
    z-index: -1;
    border-radius: 3px;
}

.react-player iframe {
    width: 100% !important;
    min-height: 300px !important;
}

.clientSection {
    width: 100%;
    padding: 2px;
    height: 100px;
    overflow: hidden;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}



.slick-slide {
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;

}

.subClient {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slide_item_image {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 10px;
}


.slide_item_image img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.homeTextSection {
    width: 100%;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background: linear-gradient(139deg, #000328, #00458e);
    margin-top: -10px;
}

.homeTextSection::after {
    content: '';
    background: url('./../imgs/tri.png') no-repeat;
    position: absolute;
    background-size: contain;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    width: 200px;
    height: 200px;
}

.homeTextSection::before {
    content: '';
    background: url('./../imgs/tri.png') no-repeat;
    position: absolute;
    background-size: contain;
    background-position: center;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    left: 30px;
    width: 200px;
    height: 200px;
}

.homeTextSection h1 {
    color: #fff;
}

.homeTextSection p {
    color: #fff;
}

.gallery {
    padding: 30px;
    background: linear-gradient(239deg, #000328, #00458e);
}

.homeAbout {
    width: 100%;
    min-height: 700px;
    height: auto;
    padding: 100px;
    background: rgb(0, 0, 0);
    background: linear-gradient(139deg, #000328, #00458e);
    position: relative;
}

.homeAbout::before {
    content: '';
    background: url('./../imgs/tri_light.png') no-repeat;
    position: absolute;
    background-size: contain;
    background-position: center;
    top: 50%;
    transform: translateY(-50%);
    right: -90px;
    width: 400px;
    height: 100%;
}

.homeAboutText {
    width: 100%;
    min-height: 300px;
    height: 100%;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

}

.homeAboutText h1 {
    color: #fff;
    font-weight: 400 !important;
    font-size: var(--hs);
}

.homeAboutText p {
    color: #fff;
    margin: 30px 0;
    font-size: var(--pl);
}

.homeProcess {
    width: 100%;
    height: auto;
    padding: 60px 40px;
    background: rgb(61, 75, 104);
    background: linear-gradient(180deg, #000328, #00458e);

}

.processItem {
    height: 380px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/* .processItem img {
    position: absolute;
    width: 100%;
    height: 100%;
} */

.processItemText {
    width: 70%;
    margin: 0 auto;
    z-index: 1;
    padding-left: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.processItem h3 {
    color: #fff;
    font-size: 1.8rem;
    font-family: "Dancing Script", cursive !important;
    font-weight: 700;
}

.processItem p {
    color: #fff;
    font-size: 0.9rem;
}



.processItem.dark h3,
.processItem.dark p {
    transform: rotate(-5deg);
}

.processItem.orange h3,
.processItem.orange p {
    transform: rotate(5deg);
}

.processItem.green h3,
.processItem.green p {
    transform: rotate(5deg);
}

.processItem.purple h3,
.processItem.purple p {
    transform: rotate(-5deg);
}

.processItem.secondary h3,
.processItem.secondary p {
    transform: rotate(4deg);
}

/* 
.processItem.dark {
    background: url('./../imgs/sticky-notes/st-1.png');
    padding-top: 70px;

}



.processItem.orange {
    background: url('./../imgs/sticky-notes/st-2.png');
}




.processItem.purple {
    background: url('./../imgs/sticky-notes/st-3.png');
}

.processItem.green {
    background: url('./../imgs/sticky-notes/st-4.png');
}



.processItem.secondary {
    background: url('./../imgs/sticky-notes/st-5.png');
}

 */

/* 
.processItem .top {
    margin: 10px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.processItem .top .processIcon {
    width: 60px;
    height: 60px;
    margin-right: 10px;
}

.processItem .top .processIcon img {
    width: 100%;
}
 */


/* for show reel videos */
.videoSection2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}


.videoSection {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.videoSection.mobile {
    height: 700px !important;
}


.video-parallax {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: fixed !important;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    z-index: -1;
}

.videoSection .overlayVideo {
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translate(-50%, -50%);
    background-size: cover;
    transition: 1s opacity;
}

.videoSectionContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.videoSectionContentText {
    width: 50%;
}

/* testimonial */
.testimonial {
    width: 100%;
    height: auto;
    padding: 50px;
    background: #00458e;

}

.banner_item {
    margin-top: 50px;
}

.banner_item .banner_item_sub {
    margin: 10px;
    background-color: #020231;
    padding: 20px;
    border-radius: 10px;
    min-height: 280px;

}

.banner_item_top {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner_item_top .profile_img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 10px;
    overflow: hidden;
}

.profile .profile_img img {
    width: 100%;
    height: 100%;
}

.profile .profileText {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.profile .profileText p {
    margin-bottom: 0;
    color: #fff;
    font-weight: 300;
    font-size: 0.8rem;
}

.profile .profileText h4 {
    font-size: 1rem;
    margin-bottom: 0;
    color: #fff;

}

.banner_body {
    padding: 10px 0;
}

.banner_body p {
    color: #fff;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}


/* Display */

.display {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 30px;
}

.display div:first-child {
    height: 100% !important;
}