:root {
    --purple: #12A3F0;
    /* --purple: #fe7f42; */
    --shd: 0px 1px 10px 0px rgba(125, 43, 196, 1);
    --hh: 60px;
    --hs: 35px;
    --hxs: 20px;
    --pl: 24;
    --ps: 20px;
    --pxs: 17px;
    --blue: #0d0d49;
    --tran: #13a3f070;
    --dark: #09596f;
    --yle: #ffd32b;
    --orng: #fe6848;
    --ylwGrad: linear-gradient(90deg, #ffd32b, #fe6848);
    --blueGrad: linear-gradient(139deg, #000328, #00458e);
}

@import url('./fonts.css');
@import url('./home.css');
@import url('./aboutus.css');
@import url('./portfolio.css');
@import url('./contact.css');
@import url('./animation.css');
@import url('./responsive/res.css');
@import url('./review.css');

* {
    margin: 0;
    padding: 0;
    font-family: "Poppins", sans-serif;
}

body {
    background: #00458e !important;
}

.loader {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-small {
    border: 15px solid #f3f3f3;
    border-radius: 50%;
    border-top: 15px solid var(--blue);
    width: 100px;
    height: 100px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



.glassBlue {
    background: rgba(13, 13, 73, 0.71) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(10.6px) !important;
    -webkit-backdrop-filter: blur(10.6px) !important;
}

h1 {
    font-weight: 400 !important;
}

.goTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    outline: none;
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: var(--ylwGrad);
    z-index: 1000;
    cursor: pointer;
}

.goTop svg {
    font-size: 1.6rem;
    color: #fff;
}

.wrap {
    width: 100%;
    overflow: hidden;
    height: auto;
    display: flex;
    align-items: stretch;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}


.title {
    color: #fff;
    font-size: 3.2rem;
}

.subTitle {
    font-size: var(--hs);
    color: #fff;
}

.form-check-input {
    box-shadow: none !important;
}

.form-check-input:checked {
    background-color: var(--purple) !important;
    border-color: var(--purple) !important;
}

/* 
****************
    HEADER
***************
*/

.header {
    width: 100%;
    padding: 0 20px;
    background: linear-gradient(239deg, #000328, #00458e);
    position: fixed;
    top: 0;
    z-index: 9999;

}

.header .navbar {
    position: relative;
    z-index: 999;
    height: auto;
    width: 100%;
    background-color: transparent !important;
}

.header .navbar .nav-link {
    color: #fff !important;
    margin: 0 20px;
    line-height: 1.5;
    position: relative;
    font-weight: 300;
}

.header .navbar .nav-link:hover::after {
    width: 100%;
    transform: translateX(0);
    left: 0;
}

.header .navbar .nav-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 50%;
    width: 0;
    height: 2px;
    background-color: var(--purple);
    transition: all 0.3s linear;
    transform: translateX(-50%);
}

.header .navbar .nav-link.getInto,
.getInto {
    border-radius: 30px !important;
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    outline: none;
    border: none;
    text-align: center;
    min-width: 150px;
    background: linear-gradient(90deg, #ffd32b, #fe6848);
    transition: all 0.2s ease-in-out;
}



.header .navbar .nav-link.getInto:hover,
.getInto:hover {
    box-shadow: #ffd32b 0px 1px 20px 1px
}

.header .navbar .nav-link.getInto::after {
    content: none;
}

.navbar-toggler {
    background-color: var(--purple) !important;
    box-shadow: none !important;
}

.header .navbar .navbar-brand {
    color: #fff !important;
}

.navbar-brand img,
.offcanvas-title img {
    width: 200px;
}


.redMore,
.btn-link {
    font-size: 1rem;
    color: #fff !important;
    margin-top: 20px;
    font-weight: 200;
    transition: all 0.3s linear;
    text-decoration: none !important;
    border-bottom: 1px solid #fff !important;
    border-radius: 0 !important;
    padding: 5px 0 !important;

}

.redMore:hover,
.btn-link:hover {
    color: var(--purple);
}

.redMore svg {
    margin-left: 5px;
}










/* 
****************
    FOOTER
***************
*/
.footer {
    width: 100%;
    padding: 50px 30px;
    background: var(--blueGrad)
}

.footer_link {
    display: flex;
    list-style: none;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
}

.footer_link li a {
    margin-right: 40px;
    color: #fff;
    font-weight: 300;
    font-size: 0.9rem;
}

.footer_link_social {
    display: flex;
    list-style: none;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    flex-wrap: wrap;
}

.footer_link_social li {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50px;
    transition: all 0.2s linear;
}

.footer_link_social li:hover {
    background-image: var(--ylwGrad)
}

.footer_link_social li a {
    text-decoration: none;
    color: #fff;
    font-weight: 300;
    font-size: 1.2rem;
    padding: 10px;
    transition: all 0.2s linear;
}

hr {
    border-color: #fff !important;
    border-width: 1px !important;
    opacity: 1 !important;
    margin: 30px 0 !important;
}

.subFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subFooter p {
    color: #fff;
}

.subFooter img {
    width: 200px;
}

.date {
    font-size: 0.7rem;
}