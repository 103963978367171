  /* ******************
   XXLARGE  min 992 max 1024
*********************/
  @media (min-width:1200px) {
    .react-player {
      width: 100% !important;
    }

    /* .ourMission .textDiv {
      width: 60% !important;
      padding: 30px !important; 
      top: 28% !important;
    } */
  }