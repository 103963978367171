 /*******************
   SMALL min 380px max 410
*********************/
 @media (min-width:380px) and (max-width:410px) {
   .title {
     font-size: 2.2rem !important;
   }

   .header .navbar {
     height: auto !important;
   }

   .heroSection {
     padding: 10px !important;
     background: linear-gradient(0deg, rgba(84, 8, 108, 1) 0%, rgba(0, 0, 0, 1) 8%, rgba(14, 14, 17, 1) 86%, rgba(84, 8, 108, 1) 96%);
     min-height: 1000px;
   }


   .heroSection .overlayVideo {
     height: 1000px !important;
   }

   .heroSectionText h2 {
     font-size: 1.6rem !important;
     font-weight: 500 !important;
   }

   .heroSectionText p {
     font-size: 1.2rem !important;
   }

   .videoSection {
     height: auto !important;
     padding: 20px !important;
   }

   .videoSection.mobile {
     min-height: 400px;
   }

   .react-player {
     width: 100% !important;
     height: auto !important;
     border: none !important;
   }

   .clientSection {
     overflow-x: scroll;
   }

   /* gallery */

   .gallery {
     padding: 10px 20px !important;
   }

   .gallery img {
     margin-bottom: 20px;
   }

   /* homeAbout  */
   .homeAbout {
     padding: 10px !important;
     height: auto !important;
     background: rgba(96, 90, 90, 1) !important;
   }

   .processItem .top .processIcon {
     width: 40px !important;
     height: 40px !important;
   }

   .processItem h3 {
     font-size: 1.4rem !important;
   }

   .processItem p {
     font-size: 0.9rem !important;
   }

   .homeAboutText h1 {
     font-size: 1.6rem !important;
   }

   /* About Page */
   .ourMission {
     padding: 10px !important;
     height: auto;
   }

   .ourMission .imageDiv {
     width: 100% !important;
   }

   .ourMission .textDiv {
     width: 100% !important;
     padding: 20px !important;
     position: inherit !important;
     right: 0;
   }

   .ourMission .innerText {
     width: 100% !important;
   }

   .ourMission .innerText h1 {
     font-size: 2rem !important;
   }

   h1.text-white.text-center {
     font-size: 3rem !important;
   }

   /* PortFolio */
   .portfolioSlideItemImage {
     width: 100% !important;
   }

   .animationParent {
     height: 350px;
     margin-top: 60px;
   }

   .animationParent .video {
     height: 200px !important;
   }

   .arrowImage {
     width: 60px;
     height: 60px;
     top: 60px;
     right: 0px;
   }

   .animationParent .video.active {
     left: 40%;
   }

   .portfolioSection {
     padding: 20px 10px !important;
   }

   /* A.animation */
   .animation {
     padding: 20px !important;
   }

   /* .contactUs */
   .contactUs {
     padding: 10px !important;
   }

   /* Footer */
   .footer {
     padding: 10px !important;
   }

   .subFooter {
     flex-direction: column-reverse;
   }

   .footer_link {
     justify-content: center !important;
   }

   .footer_link li {
     margin-bottom: 10px;
   }

   .subFooter p {
     margin-top: 20px;
   }

   .testimonial {
     padding: 30px 0 !important;
   }

   .react-player iframe {
     min-height: auto !important;
   }

   .homeTextSection::after {
     width: 100px;
     height: 100px;
     top: inherit !important;
     bottom: -50px !important;
     right: 0 !important;
   }


   .homeTextSection::before {
     width: 100px;
     height: 100px;
     top: 40px !important;
     left: 0 !important;
   }


   /* profile */
   .portfolioDetail {
     margin-top: 30px !important;
   }

   .portfolioDetail .hero-video {
     min-height: 200px !important;
   }

   .portfolioDetail .portfolioDetailChallenges {
     padding: 50px 20px !important;
   }

   .portfolioDetail .portfolioDetailChallengesText {
     padding: 0 !important;
   }

   .portfolioDetail .portfolioDetailChallengesText h3 {
     font-size: 2.3rem !important;
   }

   .portfolioDetail .headingTextArea h2 {
     font-size: 3rem !important;
   }

   .titleSection {
     font-size: 2.3rem !important;
     margin-top: 30px;
   }

   .portfolioDetailBehindTheSeen {
     padding: 10px !important;
   }

   .processItem {
     height: 350px;
   }


   /* REVIEW */

   .reviewSection {
     padding: 10px !important;
   }

   .reviewItem {
     margin: 10px 0 !important;
   }

   .reviewItem.borderRight {
     border: none !important;
   }

   .emoges {
     flex-wrap: wrap;
     justify-content: flex-start !important;
   }

   .emoges li label {
     margin-bottom: 10px;
   }
 }