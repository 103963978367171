.contactUs {
    width: 90%;
    padding: 60px;
    display: flex;
    margin: 20px auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    margin-top: 100px;
    background: linear-gradient(0deg, #000328, #00458e);
}

.contactUs h1 {
    color: #fff;
    font-weight: 300;
    text-align: center;

}

.contactUs p {
    text-align: center;
    color: #fff;
    font-weight: 200;
}

.contactUs span {
    color: #fff;
    font-weight: 200;
}

.contactUs p a {
    color: var(--purple);
    font-weight: 400;
    text-decoration: none;
}

.contactUs form {
    margin: 50px 0;
}

.form-control {
    padding: 10px 20px !important;
    color: #fff !important;
    border-radius: 10px;
    background-color: transparent !important;
    font-weight: 300 !important;
    border-width: 0.9px !important;
}

.form-select {
    padding: 10px 20px !important;
    border-radius: 10px;
    background-color: #052459 !important;
    font-weight: 300 !important;
    border-width: 0.9px !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
}


.form-select {
    color: #fff !important;
}


.form-control:is(:hover, :focus),
.form-select:is(:hover, :focus) {
    box-shadow: none !important;

}

.form-control::placeholder {
    color: #989696 !important;
    font-size: 0.9rem;
}

.form-label {
    color: #fff;
    margin-left: 10px;
    font-weight: 300 !important;
}