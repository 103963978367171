  /* ******************
   XXXXXXLARGE  min 2560px 
*********************/
  @media (min-width:2560px) {
      .heroSection {
          height: 1200px !important;
      }

      .heroSectionText {
          padding: 50px !important;
      }

      .homeAbout {
          height: auto !important;
      }

      .processItem {
          width: 80% !important;
      }

      .banner_item .banner_item_sub {
          min-height: 350px !important;
      }

      .banner_body p {
          color: #fff;
          -webkit-line-clamp: 7;
          line-clamp: 7;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
      }

      .ourMission .imageDiv {
          width: 70%;
          height: 700px;
      }

      .ourMission .textDiv {
          height: auto;
          min-height: 500px;
          top: 50% !important;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
      }

      .ourMission {
          height: 1200px;
      }

      .ourMission .innerText p {
          font-size: 1.5rem;
      }

      .innerText2 p {
          font-size: 1.5rem !important;
      }

      .homeAboutText p {
          font-size: 1.5rem !important;
      }

      .processItem p {
          font-size: 1rem !important;
      }

      .animationParent {
          height: 800px !important;
      }

      .animationParent .video.active {
          height: 600px !important;
      }

      .animationParent .video {
          height: 600px !important;

      }

      .arrowImage {
          width: 160px;
          height: 160px;
      }

      .videoSection.mobile {
          height: 1000px !important;
      }

      .portfolioSection .img-fluid,
      .portfolioSection .img-thumbnail {
          width: 100% !important;
      }

      .gallery .img-fluid,
      .gallery .img-thumbnail {
          width: 100% !important;
      }
  }