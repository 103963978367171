.reviewSection {
    padding: 60px;
    border-radius: 10px;
    min-height: 500px;
    background: linear-gradient(0deg, #000328, #00458e);
    margin: 100px auto 30px auto;
}

.reviewSection h1 {
    color: #fff;
    font-weight: 300;
    text-align: center;
}

.reviewSection p {
    color: #fff;
    font-weight: 200;
}

.reviewItem {
    width: 100%;
    padding: 10px;
    margin: 30px 0;
}

.reviewItem.borderRight {
    border-left: 1px solid #fff;
    padding-left: 20px;

}

.reviewItem h4 {
    color: #fff;
    margin-bottom: 0;
}

.reviewItem p {
    color: #979595;
    font-size: 0.9rem;
}

.emoges {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.emoges li {
    display: flex;
    justify-content: center;
    align-items: center;

}

.emoges li label {
    cursor: pointer;
    width: 100px;
    font-size: 0.7rem;
    text-align: center;
    padding: 5px;
    transition: ease-in-out, 0.5s;
    border-radius: 10px;
}

.emoges li label:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.emoges li label p {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 0;
    font-size: 0.8rem !important;
    color: #fff;

}

.emoges li label h3 {
    font-size: 2rem;
}

.emoges li input[type="radio"] {
    display: none;
}

.emoges li input[type="radio"]:checked~label {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.warningDiv {
    width: 100%;

}

.warningDiv h4 {
    color: red;
    margin: 20px 0;
}

.warningDiv a {
    color: #fff;
}