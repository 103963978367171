  /* ******************
   X LARGE  min 992 max 1024
*********************/
  @media (min-width:992px) and (max-width:1024px) {
    .header .navbar .nav-link {
      font-size: 0.8rem !important;
    }

    .ourMission .textDiv {
      padding: 10px !important;
      width: 60% !important;
      top: 28% !important;
    }

    .homeTextSection::after {
      width: 150px;
      height: 150px;

    }

    .heroSection {
      padding: 10px !important;
      background: linear-gradient(0deg, rgba(84, 8, 108, 1) 0%, rgba(0, 0, 0, 1) 8%, rgba(14, 14, 17, 1) 86%, rgba(84, 8, 108, 1) 96%);
      min-height: 800px;
    }


    .heroSection .overlayVideo {
      height: 800px !important;
    }


    .homeTextSection::before {
      width: 150px;
      height: 150px;

    }
  }


  @media (width:1024px) and (height:600px) {}